/*

This shim replaces the following compass @imports

@import "compass/css3/animation";
@import "compass/css3/transform";
@import "compass/css3/opacity";

*/


@mixin animation($name, $duration) {
  animation-name: $name;
  animation-duration: $duration;
}

@mixin keyframes($name) {
  @keyframes #{$name} { @content; }
}


@mixin transform($arg) {
  transform: $arg;
}

@mixin opacity($arg) {
  opacity: $arg;
}


/* 
 * Taken from Bootstrap 4.x
 */
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}