

/*
 * Top Navbar 
 */
 
// TODO: change this to include the px
$top_nav_bar_height: 60px;
$top_nav_bar_font_family: $ff-headers;
$top_nav_bar_background_color: $red;
$top_nav_bar_foreground_color: white;
$top_nav_bar_bottom_border_color: rgba(0,0,0,0.1);

/* Top Navbar, Center */
$top_nav_bar_center_foreground_color: $top_nav_bar_foreground_color;
$top_nav_bar_center_slogan_color: $top_nav_bar_foreground_color;

/* Top Navbar, Left Side */
$top_nav_bar_leftside_background_color: rgba(0,0,0,0.1);
$top_nav_bar_menubars_foreground_color: $top_nav_bar_foreground_color;
$top_nav_bar_menubars_background_color: rgba(0,0,0,0.2);
$top_nav_bar_menubars_thickness: 4;
$top_nav_bar_menubars_width: 32;



/* 
 * "Page" navigation
 */
 
$page_navigation_mode_fixed: true;
$page_navigation_fixed_top: 65px; // should be $top_nav_bar_height + ~5px for padding
 
$page_navigation_bg: #F6F6F6; 
//$page_navigation_bg: url('https://i.ytimg.com/vi/HDkCcWWmLKQ/maxresdefault.jpg');

$page_navigation_top_bar_color: $red;
$page_navigation_top_bar_thickness: 4px;
$page_navigation_width: 270px;
$pagenav_width: $page_navigation_width;

// comment these variable out if you don't want them
//$page_navigation_inner_max_height: 350px;
$page_navigation_inner_max_height: calc((100vh - #{$page_navigation_fixed_top}) * .75);
//$page_navigation_outer_max_height: calc(100vh - 360px);

$page_navigation_font_family: $ff-headers;
$page_navigation_font_size: 14px;
$page_navigation_line_height: 1.2em;


// Title Prefix
$page_navigation_prefix_color: #999;
$pagenav_prefix_color: $page_navigation_prefix_color;


// Regular links
$page_navigation_link_color: red;
$page_navigation_link_color_hover: darken(red, 30);
$page_navigation_active_link_color: red;
$page_navigation_active_link_color_hover: darken(red, 30);
$pagenav_prefix_font_size: 14px;

// Sections before/after
$page_navigation_sections_link_color: #888;
$page_navigation_sections_link_color_hover: #333;
$page_navigation_sections_active_link_color: #333;



/*
 * "MMenu" side hamburger menu
 */
$enable_mmenu_left_on_desktop: false;
$mmenu-background-color: #EEEEEE;
$top-nav-center-font-family: $ff-headers;

$mmenu-list-font-size: 15px;

$mmenu_active_trail_color: $page_navigation_active_link_color;
$mmenu_active_trail_show_icon: false; 

// Main content area
$main_content_max_width_mobile: 400px; 
$main_content_max_width_tablet: 400px;
$main_content_max_width_desktop: 720px;
$main_content_max_width_wide: 880px;


// Footer
$rk_footer_height: 60px;

//
$admin-menu-height: 28px;



/* old project overrides */


// /*
// * Top Navbar 
// */
 
// // TODO: change this to include the px
// $top_nav_bar_height: 60px;
$top_nav_bar_font_family: $ff-headers;
$top_nav_bar_background_color: red;
$top_nav_bar_foreground_color: white;
// $top_nav_bar_bottom_border_color: rgba(0,0,0,0.1);


// /* Top Navbar, Center */
// $top_nav_bar_center_foreground_color: $top_nav_bar_foreground_color;
$top_nav_bar_center_slogan_color: lighten(red, 20);

// /* Top Navbar, Left Side */
// $top_nav_bar_leftside_background_color: rgba(0,0,0,0.1);
// $top_nav_bar_menubars_foreground_color: $top_nav_bar_foreground_color;
// $top_nav_bar_menubars_background_color: rgba(0,0,0,0.2);
// $top_nav_bar_menubars_thickness: 4;
// $top_nav_bar_menubars_width: 32;



// /* 
// * "PageNav" navigation
// */
 


$pagenav_title_bg_color: darken(red, 20); 
$pagenav_title_color: $white;

$page_navigation_top_bar_color: darken(red, 10);
// $page_navigation_top_bar_thickness: 4px;
$page_navigation_width: 300px;
$pagenav_width: $page_navigation_width;

// // comment these variable out if you don't want them
// //$page_navigation_inner_max_height: 350px;
// $page_navigation_inner_max_height: calc((100vh - #{$page_navigation_fixed_top}) * .75);
// //$page_navigation_outer_max_height: calc(100vh - 360px);

$page_navigation_font_family: $ff-headers;
$page_navigation_font_size: 16px;
// $page_navigation_line_height: 1.2em;


// // Title Prefix
// $page_navigation_prefix_color: #999;
// $pagenav_prefix_color: $page_navigation_prefix_color;


// // Regular links
$page_navigation_link_color: red;
$page_navigation_link_color_hover: darken(red, 20);
$page_navigation_active_link_color: darken(red, 20);
$page_navigation_active_link_color_hover: darken(red, 40);
// $pagenav_prefix_font_size: 14px;

// // Sections before/after
// $page_navigation_sections_link_color: #888;
// $page_navigation_sections_link_color_hover: #333;
// $page_navigation_sections_active_link_color: #333;

 

// /*
// * "MMenu" side hamburger menu
// */
// $enable_mmenu_left_on_desktop: false;
// $mmenu-background-color: #EEEEEE;
// $mmenu-header-font-family: $ff-headers;

// $mmenu-list-font-size: 15px;

// $mmenu_active_trail_color: red;
// $mmenu_active_trail_show_icon: false; 

// // Main content area
// $main_content_max_width_mobile: 400px; 
// $main_content_max_width_tablet: 400px;
// $main_content_max_width_desktop: 720px;
// $main_content_max_width_wide: 880px;


// // Footer
// $rk_footer_height: 60px;

// // 
// $admin-menu-height: 28px;

