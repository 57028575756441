#sliding-popup {
  &.sliding-popup-bottom,
  &.sliding-popup-top {
    background: darkblue;
  }
  .popup-content #popup-text button {
    font-family: $ff-headers;
    line-height: 1.2em !important;
    display: block;
  }
}

