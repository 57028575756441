ul,
ul.rk_level1 {
  li p {
    margin: 0;
  }
}

ul.rk_tight {
  margin: 0;
  li {
    margin: 0;
  }
}

.col-md-6 ul {
  margin-left: 0;
}



ul.rk_level1 {
  list-style: none;
  li {
    position: relative;
    &:before {
      content: '➤';
      position: absolute;
      left: -1.5em;
      font-weight: 900;
    }
  }
  & > li > ul {
    margin-top: .75em;
    list-style: none;
    li:before {
      content: '+';
      color: #999;
    }
  }
}



p.Bulleted {
  margin-left: 50px;
  position: relative;
  & > b {
    position: absolute;
    left: -25px;
  }
  .rk_level2 & {
    margin-left: 10px;
    
    
    // Not sure if this is universal or not...
    li:before {
      content: '+';
      float: left;
      margin-left: -0.5em;
      font-weight: bold;
      color: #999;
    }
    
  }
}


ol {
  li {
    padding-left: 10px;
    &::marker {
      font-weight: bold;
    }
  }
}