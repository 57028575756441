$lato: 'Lato', 'Verdana', sans-serif;
$fira-sans-cond:  'Fira Sans Condensed', sans-serif;
$inconsolata: 'Inconsolata', monospace;
$ff-script: 'Kaushan Script', script;

$mono: $inconsolata;

// For theme demo to work, list fonts like this, with single quotes:
// $lato: 'Lato', 'Verdana', sans-serif;

// Base & Headers Font Family
$ff-base: $lato;
$ff-headers: $lato;

// Accent Serif 1
$ff-accent-serif-1: $ff-headers;

// Accent Condensed 1
$ff-accent-cond-1: $ff-base;
$ff-accent-cond-2: $ff-headers;

/*
 * This is for while the fonts are loading
 * If using a font with especially unusual height you might need to adjust it here to reduce jump
 */

/*
.wf-loading {
  h1.page-title {
    font-size: 52px !important;
  }
}
*/

