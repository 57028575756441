/* Color Palette for this project */
$red: #EB2010;  
$tan: #6E5F63;



/* good to have */
$black: #000000;
$white: #FFFFFF;

/* Assignments */
$color-accent-base-1: green;
$color-accent-base-2: green;

$color-accent-light-1: lightblue;
$color-accent-light-2: lightgreen;

$color-accent-dark-1: darkblue;
$color-accent-dark-2: darkgreen;

$color-accent-bg-1: blue;
$color-accent-bg-2: green;
