body.page-node-1844 {
  .layout-3col {
    overflow-x: unset;
  }
}

.sticky-header thead td {
  position: sticky;
  top: 59px;
}

body.page-node-1844.adminimal-menu .sticky-header thead td {
  top: 85px;
}

.block-webform h2 {
  display: none;
}

.webform-component--zip-code {
  margin-bottom: 2em;
}