// body {
//   article {
//     min-height: 100vh;
//     & > .content > section {
//       & > .wrapper {
//       }
//     }
//     & > aside {
//     }
//   }
// }


// body.page-node {
//   .layout-center {
//     max-width: 990px;
//     overflow: hidden;
//   }
// }



// .primary-content-wrapper,
// .block-views,
// .search-wrapper,
// main .layout-center, 
// article > .content > section > .wrapper, 
// body.node-type-page article.node {
//   margin-top: 0;
//   margin-bottom: 0;
//   margin-left: 5px;
//   margin-right: 5px;
//   padding-left: 0;
//   padding-right: 0;
//   @include mq($from: tablet) {
//     margin-left: 5px;
//     max-width: calc(100% - #{$pagenav_width} - 10px); 
//   }
//   @include mq($from: desktop) {
//     margin-left: 25px;
//     max-width: calc(100% - #{$pagenav_width} - 25px - 25px);
//   }
//   @include mq($from: wide) {
//     margin-left: calc(max(25px , ((100% - #{$pagenav_width} - #{$main_content_max_width_wide} - 50px )/2)));
//     max-width: $main_content_max_width_wide;
//     margin-right: calc(#{$pagenav_width} + 25px);
//   }
// }


article.node-section {
  margin-top: 30px;
}


.webform-confirmation,
main > .block-webform {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 0;
  padding-right: 0;
  @include mq($from: tablet) {
    margin-left: 5px;
    max-width: calc(100% - #{$pagenav_width} - 10px); 
  }
  @include mq($from: desktop) {
    margin-left: 25px;
    max-width: calc(100% - #{$pagenav_width} - 25px - 25px);
  }
  @include mq($from: wide) {
    margin-left: calc(max(25px , ((100% - #{$pagenav_width} - #{$main_content_max_width_wide} - 50px )/2)));
    max-width: $main_content_max_width_wide;
    margin-right: calc(#{$pagenav_width} + 25px);
  }
}


.webform-component label {
  margin: 0;
}

.webform-component {
  margin-top: 1em;
}

.webform-confirmation + .links {
  display: none;
}