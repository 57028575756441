.rk-border {
  border: 2px solid #000;
  padding: 10px;
  margin: 0;
}

.rk-border p:last-child {
  margin: 0;
}

.rk-border + p {
  margin-top: 1em;
}

.rk-border + .rk-border {
  border-top: 0;
}


/* red > bullets */

span.rk-wingdings {
  font-size: 0px;
  margin-right: .5em;
  line-height: 0px;
  &:after {
    content: '✓';
    color: $red;
    font-weight: bold;
    font-size: 17px;
    padding-right: .5em;
  }
}


.MsoBlockText {
  margin: 1.5em;
  font-weight: 300;
}

.notebox-wrapper {
  break-inside: avoid;
  page-break-inside: avoid !important;
  border: 2px dotted red;
  border-left: 0;
  padding: 4px 8px;
  margin: 2em 0;
  position: relative;
  margin-left: 26px;
   
  @extend .fas-icon;
  @extend .fas-absolute;
  &:before {
    content: "\f0eb";
    position: absolute;
    width: 26px;
    background: red;
    top: -2px;
    bottom: -2px;
    left: -28px;
    color: #FFF;
    font-size: 24px;
    line-height: 1em;
    padding-top: 5px;
  }
  
  .notebox-wrapper {
    border: none;
    padding: inherit;
    &:before {
      background: none;
      color: $red;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}

/* blockquote with bubble graphic */

p.QuoteIn-Line {
  position: relative;
  font-style: italic;
  font-weight: 100;
  margin-left: 20px;
  @include mq($from: tablet) {
    margin-left: 40px;
    margin-right: 40px;    
  }
  
  .pgh-9 & {
    padding-top: 20px;
    color: $red;
    font-weight: 400;
    font-style: normal;
    @include mq($from: tablet) {
      margin-left: 145px;
      margin-right: 100px;    
    }
    &:before {
      background-image: url(/sites/all/themes/rktb_ccr/assets/elements/quote.svg);
      content: " ";
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      
      width: 75px !important;
      height: 75px !important;
      float: left;
      margin-right: 10px;
  
      @include mq($from: tablet) {
        width: 125px !important;
        height: 125px !important;
        position: absolute;
        left: -135px;
        top: 10px;
      }
    }
    /*
    &:after {
      background-image: url(/sites/all/themes/rktb_ccr/assets/elements/line_horiz_black_1.png);
      width: 100%;
      height: 16px;
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-size: contain;
      background-repeat: no-repeat;
    }
    */
  }
}

p.QuoteIn-Line + p.MsoCaption {
  font-style: italic;
  margin-left: 20px;
  @include mq($from: tablet) {
    margin-left: 145px;
    margin-right: 100px;
  }
}



.jlh_box1 {
  break-inside: avoid;
  page-break-inside: avoid !important;
  .rk-border {
    border-color: $red;
  }
  .rk-border:first-child {
    background-color: $red;
    color: #FFF;
  }
  margin-top: 1em; 
  margin-bottom: 2em;
}


.jlh-box-thebasics,
.jlh-box-therule {
  display: flex;
  margin: 1em 0;
  padding: 0;
  border: 0;
  .rk-border {
    border: 0;
    
  }
  .left {
    background: black;
    color: #FFF;
    text-transform: uppercase;
    padding: 10px;
    flex: 0 0 150px;
    text-align: center;
  }
  .right {
    background: #E3E3E3;
    padding: 10px;
    flex: 1;
  }
  .rk-wingdings {
    display: none;
  }
}
 
.jlh-box-thebasics {
  .left {
    background: $red;
  }
} 

.foo {  
   .rk-border:first-child {
    background: $red;
    color: #FFF;
    flex: 1 0 175px;
    display: flex;
    /* align-items: center; */
    text-transform: uppercase;
  }

  .rk-border:last-child {
    background: #E3E3E3;
    font-size: 15px;
    line-height: 1.6em;
  }

  .rk-border p:last-child {
    margin: 0;
  }
}

section.pgh-58.jlh-box-orderprecedents {
 .jlh_box1 {
    max-width: 400px;
    margin: auto;
    p.TableNoleaders {
      margin-bottom: .1em;
    } 
 }
}
