.pagenav-drawer-open {
  font-size: 14px;
  display: block;
  justify-content: center;
  text-align: left;
  line-height: 1;
  align-items: center;
  
  width: 240px;
  background: $tan;
  color: #FFF !important;
  font-family: $fira-sans-cond;
  font-size: 15px;
  margin: 0 5px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: .375rem .5rem;
  &:hover {
    color: #DDD;
  }
  &:before {
    font-size: 22px;
    padding-bottom: 5px;
    .pagenav-before & {
      padding-right: 10px;
    }
  }
}

a.pagenav-drawer-open {
  color: #FFF;
}

.pagenav-before {
  display: flex;
  justify-content: center;
  padding: 10px;
}


.viewby {
  display: flex;
  justify-content: center;
  margin: 8px;
  .btn {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 0px !important;
    color: #FFF;
    font-size: 16px !important;
    background: rgba(255,255,255,0.1) !important;
    box-shadow: none !important;
    border: none;
    border-bottom: 4px solid transparent;
    &:hover {
      background: rgba(255,255,255,0.3) !important;
    }
    &.active {
      border-bottom: 4px solid #FFF;
    }
  }
}