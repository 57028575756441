table.MsoNormalTable {
  width: 100%;
  border: 0;
  margin: 2em 0 4em 0;
  tr {
    border-bottom: 1px solid #DDD;
    vertical-align: top;
    td {
      border: 0;
    }
  }
  tr:nth-child(2n) {
    background: #F9F9F9;
  }


  &.table-qa {
    margin-bottom: 1em;
    tr {
      background: none;
      td {
        border: 1px solid #CCC;
        p {
          font-size: 15px;
          line-height: 1.4;
          margin-bottom: 1em;
        }
      }
    }
  }

  &.table-qa+p {
    margin-bottom: 2em;
    line-height: 1.4em;
  }


}




.rk-table-summary-name-change {
  width: 100%;
  thead td {
    background: #000;
    color: #FFF;
    border: 1px solid #777;
  }
  tbody {
    td {
      border: 1px solid #999;
    }
    tr:nth-child(2n +1) td {
      background: #EEE;
    }
  }
}


body.section-district-court-addresses {
  table.MsoTableGrid {
    width: unset;
    table-layout: fixed;
    td {
      border: none;
      width: unset;
      width: 50%;
      p {
        line-height: 1.2;
        margin-bottom: 1em;
      }
    }
  }
}  
