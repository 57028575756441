.jlh-callout-legalform-form,
.jlh-callout-document {
  
  margin: .3em .6em .6em .6em;
  padding: .6em .6em;
  p {
    font-size: 10px;
    line-height: 1.4;
    font-weight: 400;
  }
  @include mq($from: tablet) {
    margin: 1em 2em 2em 2em;
    padding: 2em 2em;
    p {
      font-size: 16px;
    }
  }

  border: 1px solid #999;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
  background: #f9f9f9;
  font-family: $mono;

  .rk-marginleft-5 {
    margin-left: 0;
  }
  .jlh-callout-document { // in case it gets nested
    margin: inherit;
    padding: inherit;
    border: inherit;
    box-shadow: inherit;
    background: inherit;
  }
  table {
    float: right;
    width: 100%;
    max-width: 300px;
    border-left: 1px solid;
    td {
      border: none;
    }
  }
  br {
    display: inherit;
    outline: inherit;
    height: inherit;
    content: inherit;
  }
  

  .ComplaintFormLetters {
      position: relative;
      &:after {
        content: '\f05a';
        color: #eb2010;
        padding-left: .5em;
        font-size: 16px;
        margin: 0!important;
        line-height: 16px;
        font-weight: 900;
        font-family: "font awesome 5 free";
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    }


  // In the united states...
  .jlh-callout-legalform-form-inthe {
    margin-bottom: 2em;
  }

  .jlh-callout-legalform-explanation {
    .jlh-callout-hidden {
      display: none;
    }
  }
  
  .jlh-callout-legalform-explanation-detail {
    .ComplaintFormLetters {
      font-weight: bold;
    }
  }
}


.jlh-callout-legalform-tip-body {
  .bt-content {
    color: #FFF;
    font-size: 13px !important;
    line-height: 1.4em;
  }
}


/* casebox */
.casebox_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
  .casebox_a {
    border-top: 2px dashed #777;
    border-bottom: 2px dashed #777;
    border-right: 2px dashed #777;
    flex: 1 0 45%;
    white-space: nowrap;
    position: relative;
    &:before,
    &:after {  
      content: 'X';
      display: block;
      position: absolute;
      right: -0.25em;
      line-height: 1em;
      background: #f9f9f9;
      z-index: 999;
      font-weight: 500;
    }
    &:before {  
      top: -0.5em;
    }
    &:after {  
      bottom: -0.5em;
    }
  }
  .casebox_b {
    flex: 0 1 45%;
    padding-left: 5%;
  }
}
