figure {
  figcaption {
    text-align: center;
    font-weight: normal;
    font-style: italic;
  }
}



figure.image-cartoon {
  max-width: 700px;
  margin: 40px auto;
}