/*
 * Classes in this file are @extended by both pagenav and mmenu
 *
 */
 
nav.mm-rpkn, nav.rpkn-pagenav {
  font-family: "Lato";   
}
 
.rknav_headertitle {
  text-transform: uppercase;
  letter-spacing: 1px;
}

 
.rknav_a {
  font-weight: 500 !important;
   .pagenav_prefix {
     //width: 30px;
   }
   .pagenav_title {
   }

}

.prev-next-links,
li.pg-appendix {
  .pagenav_title span:nth-child(2), 
  .pagenav_title span:nth-child(3) {
    display: none;
  }
}