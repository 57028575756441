/*
 * Stuff applied to all paragraphs of type "text"
 */
 
.paragraphs-item-text  {
  /*
  &:nth-child(1) {
    display: none;
  }
  .field-name-field-headline {
    display: none;
  }
  
  */
}


article > .content > section.wide > .wrapper {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  @include mq($from: tablet) {
    margin-left: 5px;
    max-width: calc(100% - 300px) !important;
  }
  @include mq($from: desktop) {
    margin-left: 125px;
    max-width: calc(100% - 420px) !important;
  }
  @include mq($from: wide) {
    margin-left: calc((100% - 1050px)/2);
    max-width: 1200px !important;
  }
}

