// See https://cdnjs.com/libraries/font-awesome
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css'); //58k

// Regular
.fa-icon:before, 
.far-icon:before,
.fas-icon:before, 
.fab-icon:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  width: 1.5em;
}


// Regular
.fa-icon:before, 
.far-icon:before {
  font-family: "Font Awesome 5 Free";
}

// Solid
.fas-icon:before {
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

// Brands
.fab-icon:before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.fa-absolute,
.far-absolute, 
.fas-absolute,
.fab-absolute, {
  position: relative;
  &:before {
    position: absolute;
    left: -18px;
  }
}