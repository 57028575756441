body.media-print {
  header,
  .print-content {
    max-width: 1000px;
    margin: auto;
  }
  .onlyprint {
    display: block;
  }
  
  .rk-wingdings {
    color: transparent;
  }
}
