body.front {
  /* enable to hide the top navbar on home page */
  /*
  div#top_nav_bar {
    box-shadow: none;
    .top-nav-bar__top-row {
      .top-nav-center, 
      .top-nav-right {
        display: none;  
      }
    }
  }
  */
  .page-background-holder {
    background-image: url(/sites/all/themes/rktp_jailhouselaw/assets/bg/bg-light.png);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    z-index: -1;
  }


  div#top_nav_bar {
    h1 {
      display: none;
    }
  }


  h1 {
    font-size: 0;
    position: relative;
    .ab {
      display: flex;
      align-items: center;
    }
    .a {
      width: calc(100vw - 135px);
      @include mq($from: desktop) {
        transform: rotate(-90deg);
        display: block;
        transform-origin: top left;
        position: absolute;
        left: -80px;
        top: 450px;
        width: 400px;
      }

    }
    .b {
      display: block;
      width: 250px;
      margin-right: 10px;
    }
    .c {
      display: block;
      width: 300px;
    }
  }


  h2 {
    font-weight: 800;
    font-size: 28px;
    @include mq($from: tablet) {
      font-size: 36px;
    }
    border: 0;
    line-height: 1.2em;
    margin-bottom: .5em;
    font-family: 'Special Elite';
    text-transform: uppercase;
    padding: 10px;
    border-bottom: 4px solid #333;
    padding-bottom: 0;
  }

  .black_bg {
    background-image: url('/sites/all/themes/rktp_jailhouselaw/assets/bg/981cb15f.png');
    background: #333;
    height: 500px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .cover_art_a {
    margin-top: -30px;
    position: relative;
    @include mq($from: desktop) {
      margin-top: 0;
      position: absolute;
      bottom: -357px;
      right: 263px;
      width: 800px;
    }
  }

  .tan_bg {
    background: #f6f6f6;
    position: absolute;
    width: 350px;
    height: 123%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -2;
  }

  .card .inner {
    @include mq($from: desktop) {
      margin-right: 229px;
    }
    padding: 10px;
  }

  .published_by {
    font-family: 'Lato';
    text-transform: uppercase;
    font-weight: 400;
    color: #555;
    letter-spacing: 3px;
    font-size: 14px;
    line-height: 1;
  }

  .published_by .a {
    width: 250px;
  }

  .published_by .b {
    width: 200px;
    @include mq($from: desktop) {
      width: 350px;
    }
    display: block;
  }

  .published_by .c {
    @include mq($from: desktop) {
      width: 340px;
    }
    display: block;
  }

  .published_by .c img {
    width: 55px;
    @include mq($from: desktop) {
      width: 75px;
    }
  }

  .edition {
    width: 75px;
    height: 60px;
    padding: 10px 5px;
    right: 10px;
    @include mq($from: desktop) {
      width: 125px;
      height: 80px;
      padding: 20px 10px;
      right: 300px;
    }
    position: absolute;
    top: 0;
    background: #FFF;
    z-index: 2;
    font-family: 'Lato';
    font-weight: 300;
    .a {
      font-size: 20px;
      line-height: 1;
      @include mq($from: desktop) {
        line-height: inherit;
        font-size: 40px;
      }
      display: block;
      text-align: center;
      margin-bottom: 0px;
    }
    .b {
      font-size: 8px;
      letter-spacing: 0;
      font-weight: bold;
      @include mq($from: desktop) {
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 400;
      }
      display: block;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .masthead {
    display: none;
    .wrapper {}

    h1#page__title {}
  }

  .tagline,
  .page_title_prefix {}

  .image {
    display: none;
  }

}
