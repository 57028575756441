/* 
 * Seach form
 */
.block.rpkn-site-search {
  body.front & {
    //display: none;
  }
  input[type='submit'] {
    color: rgba(255,255,255,0.8);
  }
}

/* This is the dropdown showing your sugestions */
#autocomplete { 
  background: #F14A43;
  li {
    color: #FFF;
  }
}


/* 
 * Search Results Page
 */
.search-wrapper {
  > h2 {
    display: none;
  }
  .search-results {
    .search-result {
      mark {
        background-color: #a9cad8;
      }
    }
  }
}



