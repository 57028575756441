html, body {
  font-size: 15px;
  @include mq($from: tablet) {
    font-size: 17px;
  }
  line-height: 1.6rem;
  font-weight: 300;
}

p {
  margin-top: 0em;
  margin-bottom: 0.75em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $ff-headers;
}

h1 {
  font-size: 30px;
  @include mq($from: desktop) {
    font-size: 42px;
  }
}

h2 {
  font-size: 24px;
  @include mq($from: desktop) {
    font-size: 28px;
  }
}

h3 {
  font-size: 20px;
}


h4 {
  font-size: 18px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-style: italic;
}

h5+h6 {
  margin-top: 1em;
}

strong, b {
  font-weight: 600;
  body.pg-1 & {
    font-weight: 500;  
  }
}



img {
  max-width: 100%;
} 

span.dropcap {
  float: left;
  font-family: Georgia;
  font-size: 75px;
  line-height: 60px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

address {
  line-height: 1.2;
  margin-left: 1rem;
}

.NormalBold {
  font-weight: bold;
}

.NormalItalics {
  font-style: italic;
}

