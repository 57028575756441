

$pagenav_level1_inline: false;
$pagenav_level2_inline: true;
$pagenav_level3_inline: true;
$pagenav_level4_inline: true;

/* ********************************* */

/*
 * Don't show PageNav on mobile at all
 */

@include mq($until: tablet) {
  nav.rpkn-pagenav,
  .bottom_nav_wrapper {
    display: none;
  }
}

.pagenav-before {
  background: #DDD;
}


.masthead .page_title_prefix:before,
.nav_header .pagenav_prefix:before {
  //content: 'Category ';
  font-family: $ff-headers;
}

nav.rpkn-pagenav .block__title,
nav.mm-rpkn .mm-header {
  @extend .rknav_headertitle;

  background: #000;
  color: #FFF;
  font-weight: 800;
  font-family: 'Special Elite';
  padding: 10px 0 2px 0;

}

nav.rpkn-pagenav {
  border-top: 0;
  .nav_header {
    display: none !important;
    min-height: 50px;
    display: block;
    .pagenav_prefix {
      color: rgba(255, 255, 255, 0.8);
      font-size: 12px;
    }
    .pagenav_title {
      color: yellow;
    }
  }
  .page-links,
  .child-links,
  .prev-next-links {
    ul {
      li {
        ul {
          padding-left: 10px;
        }
      }
    }
    li {
      >a,
      >.nolink {
        font-weight: 500;
        line-height: 1.1em; // display: flex; // comment this to stack prefix on top of page_title 
      }
    }
    .pagenav_prefix {
      font-size: 12px !important;
      color: rgba(0, 0, 0, 0.3) !important;
    }
  }

  .page-links {
    padding-top: 5px;
    .pagenav_title {}
    a.active {
      color: #660000;
      .pagenav_title {}
      /*
       * background image behind active menu item
       *
      &:before {
        display: block;
        content: '';
        position: absolute;
        background-image: url(../images/pagenav_background.png);
        width: 260px;
        height: 210px;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        left: -30px;
        top: -5px;
      }
      */
    }
  }
  @if $pagenav_level2_inline == true {
    .rknav-l2 {
      a {
        display: flex;
      }
    }
  }
  @if $pagenav_level3_inline == true {
    .rknav-l3 {
      a {
        display: flex;
      }
    }
  }
  @if $pagenav_level4_inline == true {
    .rknav-l4 {
      a {
        display: flex;
      }
    }
  }

  .rknav-l1 {
    /*   margin-left: 20px; */
    .openclose {
      //     display: none;
      top: 6px;
      &:before {
        //       color: transparent;
      }
    }
    &:hover {
      .openclose:before {
        color: #AAA;
      }
    }
  } // This belongs in the pagenav module...
  .openclose {
    @extend .fas-icon;
    &:before {
      content: '\f0d7';
    }
  }


}

/*
 * menu separator with no links
 */

nav.mm-rpkn,
nav.rpkn-pagenav {
  .pagenav-separator {
    background: #a9c0e0;
    padding: 4px 0 !important;
    font-size: 14px !important;
    text-align: center;
    margin-bottom: 5px !important;
    display: block;
    .pagenav_title {
      color: #025A8A !important;
      font-weight: bold !important;
      text-transform: uppercase;
    }
  }
}



.pagenav_drawers .drawer {
  i.closebox {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 26px;
    opacity: .7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  opacity: 0;
  position: absolute;
  width: 200%;
  min-height: 75%;
  top: 5%;
  left: 0px;
  transition-duration: .5s;
  z-index: -5;
  color: #FFF;

  background-color: $tan;
  .inner {
    padding: 10px;
    .header {
      line-height: 1.3;
      font-weight: 300;
      margin-bottom: 1em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding-bottom: .25em;
      b {
        font-weight: 600;
        white-space: nowrap;
      }
    }
    .footer {
      border-top: 1px solid rgba(255, 255, 255, .5);
      display: flex;
      justify-content: space-evenly;
      padding-top: .5em;
      margin-top: 2em;
      button {
        background: #FFF;
        color: $tan;
        width: 200px;
        text-align: center;
        margin: 10px 10px;
        display: flex;
        &:hover {
          color: #333;
        }
      }
    }
    .cboxes {
      margin: 0 20px;
      font-weight: 300;
      .summary {
        .pages {
          font-weight: bold;

        }
      }
      ul {
        list-style: none;
        margin-left: 0;
        li {
          cursor: pointer;
          display: flex;
          line-height: 1.3;
          margin-bottom: 1.1em;
          .num {
            width: 20px;
          }
          .txt {
            flex: 1;
          }
          .pages {
            opacity: .5;
            font-size: 14px;
            float: right;
            margin-left: 10px;
          }
          &:hover {
            i {
              opacity: .75;
            }
          }
          i {
            transform: scale(1.5);
            transform-origin: top;
            margin-left: -1em;
            padding-right: .5em;
            opacity: .5;
            &.fa-check-circle {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.drawer-open {
    opacity: 1;
    left: -200%;
    transition-duration: .5s;
    box-shadow: 0 0 10px rgb(0 0 0 / 60%);
  }
}


.opt-alt .opt1 {
  display: none;
}

.opt-alt .opt2 {
  display: block !important;
}

.opt2 ul {
  margin: 0;
  padding-left: 0;
  @include clearfix();
}

.opt2 .cboxes ul li i {
  transform: scale(1.25) !important;
  margin: 0 !important;
}

.opt2 .cboxes {}

.opt2 {
  .sep {
    width: 100% !important;
    clear: both;
    font-weight: bold;
    color: rgba(255,255,255,.8);
    text-align: left;
    display: block;
    background: rgba(255,255,255,.2);
    line-height: 1;
    padding: 4px 2px 4px 8px;
    margin-bottom: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: -15px;
    border-left: 4px solid rgba(255,255,255,.8);
    font-size: 16px;
  }
  .cboxes li {
    width: 49%;
    float: left;
    display: flex;
    line-height: 1.2;
    font-size: 16px;
    &.wide {
      width: 100%;
    }
    .pages {
      display: none;
    }
  }
}

.opt2 .cboxes li:nth-child(2n+1) {
  clear: both;
  margin-right: 2%;
}




nav.rpkn-pagenav .page-links {
  .pg-appendix {
    & > a.pagenav_li {
      display: flex;
      .pagenav_prefix {
        flex: 0 0 25px;
      }
    }
    ul li {
      padding-left: 35px !important;
    } 
  }
}


li.sep-appendix {
  background: #000;
 .pagenav_title {
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .5px;
  }
}



body.pg-appendix {
  .title_and_prefix_wrapper {
    .page_title_prefix:before {
      content: 'APPENDIX ';
    }
  }
}