$mm_left_visible: "never"; // never | mobile | always
$mm_right_visible: "mobile";





#top_nav_bar {
  background: $red;
  border-bottom: 0;
  .top-nav-left {
    display: none;
    .open {
      @if $mm_left_visible == "never" {
        display: none;
      }
      @if $mm_left_visible == "mobile" {
        @include mq($from: tablet) {
          display: none;
        }
      }
    }

    font-family: $ff-accent-cond-1;
    .open {
      .menubars {
      }
      .toc {
        text-transform: none;
        font-size: 20px;
        line-height: 20px;
      }
      &:before {

      }
    }
  }
  .top-nav-center {
    padding-left: 5px;
    @include mq($from: tablet) {
      padding-left: 15px;
    }
      flex: 1 1 auto;
    h1.sitename {
      font-size: 24px;
      @include mq($from: tablet) {
        font-size: 42px;
      }
      
      padding-top: 5px;
      text-align: left;
      text-transform: uppercase;
      font-family: 'Special Elite';
      color: #FFF;
      line-height: 1;
      @include mq($from: tablet) {
        margin-left: 10%;
        line-height: 60px;
      }
      a {
        color: #FFF;
        &:hover {
          color: #FFF;
        }
      }
    }
    .header__site-slogan,
    .header__site-slogan-mobile {
      font-weight: 400;
      font-size: 18px;
    }
  }
  .top-nav-right {
    .open {
      @if $mm_right_visible == "never" {
        display: none;
      }
      @if $mm_right_visible == "mobile" {
        @include mq($from: tablet) {
          display: none;
        }
      }
    }
    
    div#mmenu-right .toc {
      display: none;
    }
    
    
    .menubars {
        margin: 0px 10px;
        position: relative;
//        height: $top_nav_bar_height;
        height: #{5 * $top_nav_bar_menubars_thickness}px;
        width: #{$top_nav_bar_menubars_width}px;
        &:before {
          font-size: 24px;
          content: "";
          position: absolute;
          left: 0px;
//          top: #{($top_nav_bar_height - (5 * $top_nav_bar_menubars_thickness)) / 2}px;
          top: calc(($top_nav_bar_height - (5 * $top_nav_bar_menubars_thickness)) / 2);
          width: #{$top_nav_bar_menubars_width}px;
          @include mq($from: tablet) {
            width: #{$top_nav_bar_menubars_width * .75}px
          }
          height: #{$top_nav_bar_menubars_thickness}px;
          box-shadow: $top_nav_bar_menubars_foreground_color 0px #{2 * $top_nav_bar_menubars_thickness}px 0px 0px, $top_nav_bar_menubars_foreground_color 0px #{4 * $top_nav_bar_menubars_thickness}px 0px 0px;
          background: $top_nav_bar_menubars_foreground_color;
        }
      }
    
  }
}
