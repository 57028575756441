/*
 * Everything before the <article> tag, including 
 * - the page header
 * - breadcrumbs
 * - drupal edit tabs
 *
 */


main .masthead {
  .wrapper {
    margin-right: 25px;
    max-width: unset; 
  }
  .background-holder {
   
  }
  .page_title_prefix {
   
  }
  
  h1#page__title span:nth-child(3) {
    display: block;
    font-size: 30px;
  }


}


