.rk-content-body,
.cke_editable_themed {
  
  h1 {
    font-weight: 700;
    color: $red;
    text-transform: uppercase;
  }
  
  h2, .Header2, .H2A {
  break-inside: avoid;
    page-break-inside: avoid !important;
    font-weight: 300;
    color: #000;
    font-size: 26px;
    @include mq($from: tablet) {
      font-size: 36px;
      span {
        font-size: 60px;
      }
    }
    border-bottom: 10px solid;
    padding-bottom: 5px;
    line-height: 1.1em;
    span {
      font-size: 40px;
      display: block;
      line-height: 1.1em;
      font-weight: 700;
      // &:after {
      //   content: '.';
      // }      
    }
  }
  
  h3, .Header3 {
    font-weight: 600;
    color: $red;
    font-size: 32px;
      line-height: 1.1em;
  }
  
  h4, .Header4 {
    font-weight: 600;
    color: $black;
    font-size: 28px;
    line-height: 1.1em;
  }
  
  h5 {
    font-weight: 600;
    color: #555;
    text-decoration: underline;
  }
  
  // Do others need this?
  h4 + h5 {
    margin-top: 1em;
  }


  p.Header7 {
    margin-bottom: 0;
    font-weight: bold;
  }
  
  p.Header8 {
    margin-bottom: 0;
    font-style: italic;
    display: block;
  }


}
// these are copied into the H2
p.H3Thin {
  display: none;
}


p.H4-STATES {
  border-bottom: 4px solid;
  font-weight: 600;
  font-size: 24px;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 0;
}