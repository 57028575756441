.rk-content-body {

  a {
    color: $red;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px dotted;
    &:visited {
      color: $red;
      font-weight: 400;
  
    }
    &:hover {
      color: darken($red, 20);
      font-weight: 400;
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }

}


// TODO fix
.pgid-24 li:before {
  background: red;
  display: none;
}

