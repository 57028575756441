

p.TablesHeaders {
  font-weight: bold;
  margin-bottom: 0;
  padding: 4px;
  font-size: 1.25em;
}

.jlh-darker {
  background: #CCC;
}
em.rk-jlh-darker {
  font-style: normal;
  background: #CCC;
}




.XXXrk-content-body br {
  content: ' ';
//outline in red
  content: ' ';
  height: 1em;
  outline: 3px solid purple;
  display: inline-block;
// hide
  //  display: none;

// // show as a space  
//   display: inline-block;
//   margin-right: .25em;
}


.rk-thank-h3 {
  font-size: 1.5rem;
}


.pgid-60 {
  .NormalItalics {
    margin-left: 1em;
    margin-right: 1em;
    display: block;
  }
}

.pgid-54 .jlh_box1 {
  b {
    margin-left: 1em;
    margin-right: 1em;
    display: inline-block;
  }
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}



.rk-marginleft-5 {
  margin-left: 20px;
  //outline: 1px solid lime;
}
.rk-textindent-5 {
  margin-left: 20px;
  //outline: 1px solid orange;
}

.pgid-59 {
  .rk-marginleft-5 ,
  .rk-textindent-5 {
    br {
      display: none;
    }
  }
}

.pgid-58 .jlh_box1 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  p {
    margin-bottom: 0;
  }
}

li.list-h3thin {
  display: none;
}


p.lead {
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 1.3em;
}

.rk-ff-script {
  font-family: $ff-script;
}

.jlh-legalform-sig .sig .rk-ff-script {
  border-bottom: 2px solid;
  display: inline-block;
  line-height: 1;
  margin-bottom: 0;
}


.rk-rm-whitespace {
  display: none;
  background: pink;
  outline: 1px solid red;
  outline-offset: .5em;
  height: 0;
  margin: 0 !important;
  line-height: 0;
  overflow: hidden;
}

// span.rk-rm-whitespace {
//   display: inline-block;
// }

p.rk-humanrights-article {
  margin-bottom: 0;
  margin-top: 1.5em;
  line-height: 1;
}

.jlh-addr-1 {
  font-weight: bold;
  margin-top: 1em;
  border-top: 1px solid #CCC;
  padding-top: 1em;
  
  
}


ul.list-tight li {
  margin: 0;
}


// .jlh-addr-1 {
//   border-top: 1px dotted blue;
// }
  
// .jlh-addr-2 {
//     border-left: 1px dotted blue;
// }
// .jlh-addr-3 {
//     border-bottom: 1px dotted blue;
// }



body.pg-appendix-m {
  p.AddressesStates > span:first-child {
    font-weight: bold;
  }
  
  .AddressesStatesDivisions {
    margin-left: 1em;
  }

  table td {
    padding-right: 3em !important;
    width: unset !important;
  }
}
