/*
 * Tint the usual cbox overlay if you like
 */

/*
#cboxOverlay {
  opacity: 1 !important;
  background: rgba(40, 67, 87, 0.95);
}
*/

#colorbox {
  @include mq($until: tablet) {
    max-width: calc(100% - 10px);
    margin: 0 5px;
    top: 15px !important;
  }
  #cboxWrapper {
    max-width: 100%;
  }
  #cboxTopCenter,
  #cboxContent {
    max-width: calc(100% - 30px);
  }
  div#cboxLoadedContent {
    max-width: 100%;
  }
}




