.mmenu-nav.mm-rpkn {
  background-color: $mmenu-background-color;
  border-top: 0 !important;
  & > .mm-header {
    font-size: 24px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    &:after {
      content: 'Main Menu';
    }


  }
  .mm-list {
    li {
      padding-left: 0;
    }
  }
  @include mq($from: tablet) {
    & > .mm-header {
      font-size: 40px;
    }
    > .mm-list {
      margin-top: 60px;
    }
  }

  & > .mm-panel {
    // outermost, main content panel, excluding header and footer
  }
  ul.mm-list {
    padding-top: 0;
    //font-size: $mmenu-list-font-size;
    li {
      a {
        
      }
      .prefix {
        text-transform: uppercase; 
        font-size: 12px;
        font-weight: bold;
        color: $page_navigation_prefix_color;
      }
      
    
      &.pg-appendix {
      
        a {
          display: flex;
          .pagenav_prefix {
            width: 1.25em;
            text-align: center;
          }
        }
      }
      /* separators */
      &.sep-appendix { 
        margin-left: -20px;
        padding-left: 20px
      }
    }
  }

}
